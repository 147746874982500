body {
  margin: 0;
  padding: 0;
  background: #ebeced;
  color: #C6BAA6;
  font-family: sans-serif;
}
body a {
  text-decoration: none;
  color: #C6BAA6;
}

.container {
  max-width: 1200px;
  padding: 8px 16px;
  margin: 0 auto;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}

.df {
  display: flex;
  flex-direction: row;
}

.col {
  display: flex;
  flex-direction: column;
}

.dfBtw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}

.btn {
  font-weight: bold;
  padding: 5px;
  border-radius: 20px;
  color: #C6BAA6;
  border: none;
  transition: .2s;
  background: none;
}
.btn:hover {
  cursor: pointer;
  background: #C6BAA6;
  color: #fff;
  border: none;
}

.textLg {
  font-size: 40px;
}

.fwb {
  font-weight: 700;
}

.mb15 {
  margin-bottom: 15px;
}

.mt15 {
  margin-top: 15px;
}

.mla {
  margin-left: auto;
}

#myVideo {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  -o-object-fit: cover;
     object-fit: cover;
  z-index: 1;
}

header.headerSec {
  background: #fff;
  padding: 20px 0;
}
header.headerSec .headerMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

header.header {
  position: relative;
  width: 100%;
  height: 100vh;
  padding: 20px 0;
  overflow: hidden;
}
header.header .headerMain {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: #fff;
  padding: 20px;
  border-radius: 9px;
}

main {
  margin-top: -200px;
}
main .saleMain {
  background: #fff;
  padding: 30px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
}
main .saleMain .btnHolder {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-top: 30px;
}
main .saleMain .btnHolder .btn {
  width: 50%;
  text-align: center;
  height: 40px;
  display: flex;
  justify-content: center;
  align-items: center;
}
main .saleMain .land {
  display: flex;
  align-items: center;
  justify-content: space-between;
}
main .saleMain .land .landImg {
  width: 50%;
  margin-right: 20px;
}

.order {
  display: flex;
  flex-direction: column;
  align-items: center;
}
.order .payment {
  display: flex;
  flex-direction: column;
  margin-top: 20px;
  background: #fff;
  padding: 30px;
  border-radius: 30px;
}
.order .payment .btn {
  padding: 20px;
}
.order .orderMain {
  margin-top: 20px;
  background: #fff;
  padding: 30px;
  border-radius: 30px;
  display: flex;
  flex-direction: column;
  width: 1100px;
}
.order .orderMain .tabs {
  width: 100%;
  min-height: 520px;
}
.order .orderMain .tabs .tab-content {
  margin-top: 20px;
  width: 100%;
}
.order .orderMain .tabs .tab-content .orderCard {
  width: 100%;
}
.order .orderMain .tabs .tab-content .orderCard .orderCardItem {
  display: flex;
  flex-direction: column;
  text-align: center;
  justify-content: center;
  align-items: center;
  width: 25%;
}
.order .orderMain .tabs .tab-content .orderCard .orderCardItem input {
  width: 50%;
  height: 20px;
  border-radius: 9px;
  text-align: center;
  color: #C6BAA6;
  border: none;
}
.order .orderMain .tabs .tab-content .orderCard .orderCardItem input:hover {
  border: none;
}
.order .orderMain .tabs .tab-content .orderCard .orderCardItem input:focus {
  border: none;
}
.order .orderMain .tabs .tab-list {
  list-style-type: none;
  display: flex;
  padding: 0;
}
.order .orderMain .tabs .tab-list .tab-list-item {
  margin-right: 15px;
  padding: 5px 10px;
  border-bottom: 2px solid #fff;
  transition: 0.2s;
}
.order .orderMain .tabs .tab-list .tab-list-item:hover {
  cursor: pointer;
  border-bottom: 2px solid #ebeced;
}
.order .orderMain .tabs .tab-list .tabActive {
  border-bottom: 2px solid #ebeced;
}/*# sourceMappingURL=style.css.map */

.submitForm {
  padding: 15% 10%;
}

.submitForm .btn {
  padding: 10px 15px;
  width: 50%;
}
.submitForm form {
  border: 1px solid #3b3e41;
  border-radius: 12px;
  padding: 20px;
  background: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.submitForm form .StripeElement  {
  margin-bottom: 50px!important;
  height: 50px!important;
  border: 1px solid #3b3e41!important;
  width: 96%!important;
  border-radius: 9px!important;
  padding: 5px!important;
}

.logo {
  color: #32325d;
  font-size: 30px;
  font-weight: 900;
}

.footerMain {
  display: flex;
  align-items: center;
  justify-content: space-between;

}

.footerMain .df span {
  margin-right: 10px;
}

.footerMain .df a {
  margin-right: 10px;
}

.policyHolder {
  padding: 0 10%;
}

.policyHolder h2{
  font-weight: bold;
}