$defGray: #ebeced;
$defWhite: #fff;
$defDark: #3b3e41;

body {
  margin: 0;
  padding: 0;
  background: $defGray;
  color: $defDark;
  font-family: sans-serif;
  a {
    text-decoration: none;
    color: $defDark;
  }
}

.container {
  max-width: 1200px;
  padding: 8px 16px;
  margin: 0 auto;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  position: relative;
  z-index: 2;
}

.df {
  display: flex;
  flex-direction: row;
}
.col {
  display: flex;
  flex-direction: column;
}
.dfBtw {
  display: flex;
  flex-direction: row;
  justify-content: space-between;
}
.btn {
  font-weight: bold;
  padding: 5px;
  border-radius: 20px;
  border: 1px solid $defDark;
  &:hover {
    cursor: pointer;
  }
}
.textLg {
  font-size: 40px;
}
.fwb {
  font-weight: 700;
}
.mb15 {
  margin-bottom: 15px;
}
.mt15 {
  margin-top: 15px;
}
.mla {
    margin-left: auto;
}
#myVideo {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
  z-index: 1;
}
header.headerSec {
  background: $defWhite;
  padding: 20px 0;
  .headerMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
header.header {
  position: relative;
  width: 99vw;
  height: 100vh;
  padding: 20px 0;
  overflow: hidden;
  .headerMain {
    display: flex;
    align-items: center;
    justify-content: space-between;
  }
}
main {
  margin-top: -200px;
  .saleMain {
    background: $defWhite;
    padding: 30px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    .btnHolder {
      display: flex;
      align-items: center;
      justify-content: center;
      margin-top: 30px;
      .btn {
        width: 50%;
        text-align: center;
        height: 40px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .land {
      display: flex;
      align-items: center;
      justify-content: space-between;
      .landImg {
        width: 50%;
        margin-right: 20px;
      }
    }
  }
}

.order {
  display: flex;
  flex-direction: column;
  align-items: center;
  .payment {
    display: flex;
    flex-direction: column;
    margin-top: 20px;
    background: $defWhite;
    padding: 30px;
    border-radius: 30px;
    .btn {
        padding: 20px;
    }
  }
  .orderMain {
    margin-top: 20px;
    background: $defWhite;
    padding: 30px;
    border-radius: 30px;
    display: flex;
    flex-direction: column;
    width: 1100px;
    .tabs {
      width: 100%;
      min-height: 520px;
      .tab-content {
        margin-top: 20px;
        width: 100%;
        .orderCard {
          width: 100%;
          .orderCardItem {
            display: flex;
            flex-direction: column;
            text-align: center;
            justify-content: center;
            align-items: center;
            width: 25%;
            input {
              width: 50%;
              height: 20px;
              border-radius: 9px;
              text-align: center;
            }
          }
        }
      }
      .tab-list {
        list-style-type: none;
        display: flex;
        padding: 0;
        .tab-list-item {
          margin-right: 15px;
          padding: 5px 10px;
          border-bottom: 2px solid $defWhite;
          transition: 0.2s;
          &:hover {
            cursor: pointer;
            border-bottom: 2px solid $defGray;
          }
        }
        .tabActive {
          border-bottom: 2px solid $defGray;
        }
      }
    }
  }
}

.submitForm {
  display: flex;
  align-items: center;
  justify-content: center;
  height: 90vh;

  form {
    width: 50%;
    height: 50%;

    iframe {
        height: 100%!important;
        width: 100%!important;
    }
  }

  .CardField-input-wrapper {
    height: 50px;
    border: 1px solid black;
  }

}